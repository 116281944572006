import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
declare var $: any

export enum RoleEnum { //this is role name enum.if we need any change in any rolename then change here like need to change from admin to systemadmin then change only here
  Level2R = 'Level 2 R',
  Level3R = 'Level 3 R',
  EntityLevel4Users = 'Entity Level 4 Users',
  Level5R = 'Level 5 R',
  Level6R = 'Level 6 R',
  SystemAdmin = 'System Admin',
  GlobalMarketAdmin = 'Global Market Admin',
  GridR = 'Grid R',
  MarketAdmin = 'Market Admin',
  MarketR = 'Market R',
  CustomerAdmin = 'Customer Admin',
  CustomerR = 'Customer R',
  SiteR = 'Site R',
  Level2Admin = 'Level 2 Admin',
  Level3Admin = 'Level 3 Admin',
  Level3_5R = 'Level 3.5 R',
  SiteSupportL1 = 'Site Support L1',
  SiteSupportL2 = 'Site Support L2',
  SiteSupportL3 = 'Site Support L3',
  UserAuditorGRID = 'User Auditor GRID',
  UserAuditorGRIDR = 'User Auditor GRID R',
  UserAuditorLevel1 = 'User Auditor Level 1',
  UserAuditorLevel1R = 'User Auditor Level 1 R',
  UserAuditorLevel2 = 'User Auditor Level 2',
  UserAuditorLevel2R = 'User Auditor Level 2 R',
  UserAuditorLevel3 = 'User Auditor Level 3',
  UserAuditorLevel3R = 'User Auditor Level 3 R'
}
//this is module enum . if need to add any module then change here.
export const RoleModuleMasterConstant = [{
  RoleModuleId: 1,
  RoleModuleName: "Create Entities",
}, {
  RoleModuleId: 2,
  RoleModuleName: "View Entities",
},
{
  RoleModuleId: 3,
  RoleModuleName: "Edit Entities",
},
{
  RoleModuleId: 4,
  RoleModuleName: "Price Cards",
},
{
  RoleModuleId: 5,
  RoleModuleName: "Edit User",
},
{
  RoleModuleId: 6,
  RoleModuleName: "View User",
},
{
  RoleModuleId: 7,
  RoleModuleName: "Create User",
},
{
  RoleModuleId: 8,
  RoleModuleName: "View SalesData",
},
{
  RoleModuleId: 9,
  RoleModuleName: "View Notifications",
},
{
  RoleModuleId: 10,
  RoleModuleName: "View Audit Logs",
},{
  RoleModuleId: 11,
  RoleModuleName: "User Verification Review",
},{
  RoleModuleId: 12,
  RoleModuleName: "Create AlarmTemplate",
},{
  RoleModuleId: 13,
  RoleModuleName: "Update AlarmTemplate",
},{
  RoleModuleId: 14,
  RoleModuleName: "View AlarmTemplate",
},{
  RoleModuleId: 15,
  RoleModuleName: "View Alarm",
},{
  RoleModuleId: 16,
  RoleModuleName: "Edit Alarm",
},{
  RoleModuleId: 17,
  RoleModuleName: "User Audit",
},{
  RoleModuleId: 18,
  RoleModuleName: "MachineType"
}, {
  RoleModuleId: 19,
  RoleModuleName: "ScheduleMessage"
}, {
  RoleModuleId: 20,
  RoleModuleName: "CupTypeDefinition"
}, {
  RoleModuleId: 21,
  RoleModuleName: "Capsules Ingredients"
}, {
  RoleModuleId: 22,
  RoleModuleName: "Remote Update Tasks Management"
}]

//this is screen enum . if need to add any screen then change here.
export const RoleScreenMasterConstant = [{
  RoleScreenId: 1,
  RoleScreenName: "Markets",
},
{
  RoleScreenId: 2,
  RoleScreenName: "Level 2 Entity",
},
{
  RoleScreenId: 3,
  RoleScreenName: "Level 3 Entity",
},
{
  RoleScreenId: 4,
  RoleScreenName: "Partner",
},
{
  RoleScreenId: 5,
  RoleScreenName: "Level 5 Entity",
},
{
  RoleScreenId: 6,
  RoleScreenName: "Level 6 Entity",
},
{
  RoleScreenId: 7,
  RoleScreenName: "Site",
},
{
  RoleScreenId: 8,
  RoleScreenName: "Machine",
},
{
  RoleScreenId: 9,
  RoleScreenName: "Move Entity",
},
{
  RoleScreenId: 10,
  RoleScreenName: "Manage DropdownList",
},
{
  RoleScreenId: 11,
  RoleScreenName: "Create PriceCardTemplate",
},
{
  RoleScreenId: 12,
  RoleScreenName: "Edit PriceCardTemplate",
},
{
  RoleScreenId: 13,
  RoleScreenName: "Assign PriceCardTemplateToMarket",
},
{
  RoleScreenId: 14,
  RoleScreenName: "Create PriceCard",
},
{
  RoleScreenId: 15,
  RoleScreenName: "Edit PriceCard",
},
{
  RoleScreenId: 16,
  RoleScreenName: "Clone PriceCard",
},
{
  RoleScreenId: 17,
  RoleScreenName: "View PriceCardPartner",
},
{
  RoleScreenId: 18,
  RoleScreenName: "View PriceCardSite",
},
{
  RoleScreenId: 19,
  RoleScreenName: "Assign PriceCardMultipleMarket",
},
{
  RoleScreenId: 20,
  RoleScreenName: "Assign PriceCardPartner",
},
{
  RoleScreenId: 21,
  RoleScreenName: "Assign PriceCardSite",
},
{
  RoleScreenId: 22,
  RoleScreenName: "Market Admin",
},
{
  RoleScreenId: 23,
  RoleScreenName: "Partner Admin",
},
{
  RoleScreenId: 24,
  RoleScreenName: "Market Users",
},
{
  RoleScreenId: 25,
  RoleScreenName: "Level 2 Users",
},
{
  RoleScreenId: 26,
  RoleScreenName: "Level 3 Users",
},
{
  RoleScreenId: 27,
  RoleScreenName: "Partner Users",
},
{
  RoleScreenId: 28,
  RoleScreenName: "Level 5 Users",
},
{
  RoleScreenId: 29,
  RoleScreenName: "Level 6 Users",
},
{
  RoleScreenId: 30,
  RoleScreenName: "Site Users",
},
{
  RoleScreenId: 31,
  RoleScreenName: "SalesData GRID",
},
{
  RoleScreenId: 32,
  RoleScreenName: "SalesData Market",
},
{
  RoleScreenId: 33,
  RoleScreenName: "SalesData Level 2",
},
{
  RoleScreenId: 34,
  RoleScreenName: "SalesData Level 3",
},
{
  RoleScreenId: 35,
  RoleScreenName: "SalesData Partner",
},
{
  RoleScreenId: 36,
  RoleScreenName: "SalesData Level 5",
},
{
  RoleScreenId: 37,
  RoleScreenName: "SalesData Level 6",
},
{
  RoleScreenId: 38,
  RoleScreenName: "SalesData Site",
},
{
  RoleScreenId: 39,
  RoleScreenName: "SalesData Machine",
},
{
  RoleScreenId: 40,
  RoleScreenName: "SalesData Discrepancy",
},
{
  RoleScreenId: 41,
  RoleScreenName: "Notification Market Admin",
},
{
  RoleScreenId: 42,
  RoleScreenName: "GridUsers",
},
{
  RoleScreenId: 43,
  RoleScreenName: "Grid RW",
},
{
  RoleScreenId: 44,
  RoleScreenName: "Data Issue Logs",
},
{
  RoleScreenId: 45,
  RoleScreenName: "System Audit Logs",
},
{
  RoleScreenId: 46,
  RoleScreenName: "Market Audit Logs",
},
{
  RoleScreenId: 47,
  RoleScreenName: "User Verification Review",
},{
  RoleScreenId: 48,
  RoleScreenName: "Create AlarmTemplate",
},{
  RoleScreenId: 49,
  RoleScreenName: "Update AlarmTemplate",
},{
  RoleScreenId: 50,
  RoleScreenName: "View AlarmTemplate",
},{
  RoleScreenId: 51,
  RoleScreenName: "List Alarms",
},{
  RoleScreenId: 52,
  RoleScreenName: "Level 3.5 Entity",
},{
  RoleScreenId: 53,
  RoleScreenName: "Level 2 Admin",
},{
  RoleScreenId: 54,
  RoleScreenName: "Level 3 Admin",
},{
  RoleScreenId: 55,
  RoleScreenName: "Level 3.5 Users",
},{
  RoleScreenId: 56,
  RoleScreenName: "SalesData Level 3.5",
},{
  RoleScreenId: 57,
  RoleScreenName: "Level2 Audit Logs",
},{
  RoleScreenId: 58,
  RoleScreenName: "Level3 Audit Logs",
},
{
  RoleScreenId: 59,
  RoleScreenName: "Close Alarm",
},
{
  RoleScreenId: 60,
  RoleScreenName: "Export Alarm",
},
{
  RoleScreenId: 61,
  RoleScreenName: "Partner Audit Logs",
},
{
  RoleScreenId: 62,
  RoleScreenName: "GridRW Audit Logs"
},
{
  RoleScreenId: 63,
  RoleScreenName: "View User Audit"
},
{
  RoleScreenId: 64,
  RoleScreenName: "View Audit Logs"
},
{
  RoleScreenId: 65,
  RoleScreenName: "View MachineType"
},
{
  RoleScreenId: 66,
  RoleScreenName: "Add MachineType"
},
{
  RoleScreenId: 67,
  RoleScreenName: "Edit MachineType"
},
{
  RoleScreenId: 68,
  RoleScreenName: "View TickerMessage"
},
{
  RoleScreenId: 69,
  RoleScreenName: "Add TickerMessage"
},
{
  RoleScreenId: 70,
  RoleScreenName: "Edit TickerMessage"
},
{
  RoleScreenId: 71,
  RoleScreenName: "View Cup Type Definition"
},
{
  RoleScreenId: 72,
  RoleScreenName: "View Capsules Ingredients"
},
{
  RoleScreenId: 73,
  RoleScreenName: "Add Capsules Ingredients"
}, {
  RoleScreenId: 74,
  RoleScreenName: "Remote Update Tasks Management"
}];

export enum RolePermissionsEnum {
  Read = 1,
  ReadWrite = 2,
  SuperAdmin = 0
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  trimOption ='blur'; // 'blur' | false = ''
  scrollSetTimeoutTime = 300;
  entityId: string = '';
  marketId: string = '';
  levelNumber: number = 0;
  nextLevelNumber: number = 0;
  currentRole: string = '';
  roleKey: string = '';
  userPermissions = [];
  allowedRoutes = [];
  userName : string = '';
  objRole =
    {
      isLevel2R: false,
      isLevel3R: false,
      isLevel5R: false,
      isLevel6R: false,
      isSystemAdmin: false,
      isGlobalMarketAdmin: false,
      isGridR: false,
      isMarketAdmin: false,
      isMarketR: false,
      isCustomerAdmin: false,
      isCustomerR: false,
      isSiteR: false,
      isLevel2Admin: false,
      isLevel3Admin: false,
      isLevel3_5R: false,
      isSiteSupportL1: false,
      isSiteSupportL2: false,
      isSiteSupportL3: false,
      isUserAuditorGRID: false,
      isUserAuditorGRIDR: false,
      isUserAuditorLevel1: false,
      isUserAuditorLevel1R: false,
      isUserAuditorLevel2: false,
      isUserAuditorLevel2R: false,
      isUserAuditorLevel3: false,
      isUserAuditorLevel3R: false,
      isAdmin: false
    };
  public cookieName = environment.cookieName;
  constructor(private spinner: NgxSpinnerService,private cookieService: CookieService,private userService:UserService,private toaster: Toaster) {

  }

  getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);

    return keys.length > 0 ? keys[0] : '';
  }

  getUserAccess() {
    return new Promise<boolean>((resolve, reject) => {
      var userId = this.cookieService.get(this.cookieName + 'userId');
      this.userService.getUserAccess(userId).subscribe(async (response) => {
        if (response != null) {
          this.levelNumber = response.data.levelNumber;
          this.nextLevelNumber = response.data.nextLevelNumber;
          this.entityId = response.data.entityId;
          this.marketId = response.data.marketId;
          this.userPermissions = response.data.userPermissions;
          this.currentRole = response.data.userDetail.roleName;
          this.userName = response.data.userDetail.fullName;
          this.roleKey = this.getEnumKeyByEnumValue(RoleEnum, this.currentRole);
          for(let key in this.objRole) {
            this.objRole[key] = false;
            }
          if (this.roleKey != '') {
            this.objRole['is' + this.roleKey] = true;
          }
          resolve(true);
        }
      });
    });
  }

  async checkUserPermissions(moduleIds, ScreenIds, PermissionIds): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.roleKey == '') {
        var userId = this.cookieService.get(this.cookieName + 'userId');
        let isLoggedIn = this.userService.getUserAccess(userId);
        isLoggedIn.subscribe(async (response) => {
          if (response) {
            this.levelNumber = response.data.levelNumber;
            this.nextLevelNumber = response.data.nextLevelNumber;
            this.entityId = response.data.entityId;
            this.marketId = response.data.marketId;
            this.userPermissions = response.data.userPermissions;
            this.currentRole = response.data.userDetail.roleName;
            this.userName = response.data.userDetail.fullName;
            this.roleKey = this.getEnumKeyByEnumValue(RoleEnum, this.currentRole);
            for(let key in this.objRole) {
							this.objRole[key] = false;
						  }
            if (this.roleKey != '') {
              this.objRole['is' + this.roleKey] = true;
            }

            if (moduleIds != undefined && moduleIds != null && moduleIds.length != 0
            && ScreenIds != undefined && ScreenIds != null && ScreenIds.length != 0
            && PermissionIds != undefined && PermissionIds != null && PermissionIds.length != 0) {
                if (this.userPermissions != undefined && this.userPermissions != null && this.userPermissions.length != 0) {
                  for (var i = 0; i < moduleIds.length; i++) {

                    if (this.userPermissions.filter(x => x.moduleId == moduleIds[i]).length > 0) {
                      var screens = this.userPermissions.filter(x => x.moduleId == moduleIds[i]);
                      if (screens != undefined && screens != null && screens.length != 0) {
                        for (var j = 0; j < ScreenIds.length; j++) {

                          if (screens.filter(x => x.screenId == ScreenIds[j]).length > 0) {
                            for (var k = 0; k < PermissionIds.length; k++) {

                              if (screens.filter(x => x.rolePermissionValue == PermissionIds[k]).length > 0) {
                                resolve(true);
                                return true;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  resolve(false);
                }
              }
          }
        });
      }
      else {
        if (this.objRole.isSystemAdmin) {
          resolve(true);
          return true
        } else {
          if (moduleIds != undefined && moduleIds != null && moduleIds.length != 0
            && ScreenIds != undefined && ScreenIds != null && ScreenIds.length != 0
            && PermissionIds != undefined && PermissionIds != null && PermissionIds.length != 0) {
            if (this.userPermissions != undefined && this.userPermissions != null && this.userPermissions.length != 0) {
              for (var i = 0; i < moduleIds.length; i++) {

                if (this.userPermissions.filter(x => x.moduleId == moduleIds[i]).length > 0) {
                  var screens = this.userPermissions.filter(x => x.moduleId == moduleIds[i]);
                  if (screens != undefined && screens != null && screens.length != 0) {
                    for (var j = 0; j < ScreenIds.length; j++) {

                      if (screens.filter(x => x.screenId == ScreenIds[j]).length > 0) {
                        for (var k = 0; k < PermissionIds.length; k++) {

                          if (screens.filter(x => x.rolePermissionValue == PermissionIds[k]).length > 0) {
                            resolve(true);
                            return true;
                          }
                        }
                      }
                    }
                  }
                }
              }
              resolve(false);
            }
          }
        }
      }
    });
  }

  async checkMenuPermissions(moduleIds): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (moduleIds != undefined && moduleIds != null && moduleIds.length != 0) {
        if (this.userPermissions != undefined && this.userPermissions != null && this.userPermissions.length != 0) {
          for (var i = 0; i < moduleIds.length; i++) {
            if (this.userPermissions.filter(x => x.moduleId == moduleIds[i]).length > 0) {
              resolve(true);
              return true;
            }
          }
          resolve(false);
        }
        else {
          var userId = this.cookieService.get(this.cookieName + 'userId');
          let isLoggedIn = this.userService.getUserAccess(userId);
          isLoggedIn.subscribe(async (response) => {
            if (response) {
              this.levelNumber = response.data.levelNumber;
              this.nextLevelNumber = response.data.nextLevelNumber;
              this.entityId = response.data.entityId;
              this.marketId = response.data.marketId;
              this.userPermissions = response.data.userPermissions;
              this.currentRole = response.data.userDetail.roleName;
              this.userName = response.data.userDetail.fullName;
              this.roleKey = this.getEnumKeyByEnumValue(RoleEnum, this.currentRole);
              for(let key in this.objRole) {
                this.objRole[key] = false;
                }
              if (this.roleKey != '') {
                this.objRole['is' + this.roleKey] = true;
              }
              if (this.userPermissions != undefined && this.userPermissions != null && this.userPermissions.length != 0) {
                for (var i = 0; i < moduleIds.length; i++) {

                  if (this.userPermissions.filter(x => x.moduleId == moduleIds[i]).length > 0) {
                    resolve(true);
                    return true;
                  }
                }
                resolve(false);
              }
            }
          });
        }
      }
    });
  }

  setPopupScroll(id){
    let  scrollTop     = $(window).scrollTop();
    let elementOffset = $('.content-wrapper').offset().top;
    let distance      = (elementOffset - scrollTop);

    let elementOffset2 = $('.main-header').offset().top;
    let distance2      = (elementOffset2 - scrollTop);
    if(distance < 0){
      distance = 0;
    }
    distance2 = distance2 * -1;
    $('#'+id).css({"top":distance, "height" :`calc(100vh - ${distance}px)`});
    $("html").addClass("cdk-global-scrollblock2");
    $('html').css({"top":- (distance2),"left" : "0"});

  }
  resetPopupScroll(isFromPopup=true){
    let  scrollTop     = $(window).scrollTop();
    let elementOffset2 = $('.main-header').offset().top;
    let distance2      = (elementOffset2 - scrollTop);
    distance2 = distance2 * -1;

    $("html").removeClass("cdk-global-scrollblock2");
    if(isFromPopup){
      $('html').css({"top" : "","left" : ""});
    }
    $('html').scrollTop(distance2)
  }
  showToasterMsg(msg,type){
    this.toaster.open({
      text: msg,
      type: type,
      position: 'top-right',
      duration: 10000
    });
  }
  showErrorMsg(err){
    this.spinner.hide();
    let errors = err.errors == undefined ? err.error : err.errors;
    if(!errors){
      this.showToasterMsg('Something went wrong. Please try again.','danger');
     }else{
       errors.forEach(element => {
         this.showToasterMsg(element.msg,'danger');
       });
     }
  }

  hasUserAccess(roleKeys: string[]): boolean {
    for (const key of roleKeys) {
      if (this.objRole[key]) {
        return true;
      }
    }
    return false;
  }
}
